import * as React from "react"

export default function Container({children, className, noDisplay = false, ...others}) {

  // let container = (noDisplay) ? '' : 'df'
  // className = (className) ? `${className} ${container}` : `${container}`

  return (
    <section className={className}>
      { children }
    </section>
  ) 
}
