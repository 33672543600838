import * as React from "react"

export default function ContainerWithBg({children, className, classBgName, noDisplay = false, ...others}) {

  let container = noDisplay ? '' : 'df'
  className = (className) ? `${className} ${container}` : `${container}`
  let containerBg = ''
  classBgName = (classBgName) ? `${classBgName} ${containerBg}` : `${containerBg}`

  return (
    <section className={classBgName} {...others}>
      <div className={className}>
        { children }
      </div>
    </section>
  ) 
}
