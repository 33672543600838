import * as React from "react"
import Quote from "../icons/Quote"

export default function Depoimento({
  text = "",
  jobTitle = "",
  company = "",
  picture = "",
  video = ""
}) {
  return (
    <div className="col-sm-7 col-md-7 col-lg-4 mb-6 mb-lg-0 d-flex align-items-center">
      <div className="bg-color-white tac c-grey round dfc p-r quote-stroke p-4">
        <div className="p-a abs-center">
          <Quote />
        </div>
        <p className="c-grey fs-body-small">{text}</p>
        <p className="c-grey-1 fs-body-small o-60">{company}</p>
        <p className="c-purple-1 fs-body-small o-70">{jobTitle}</p>
      </div>
    </div>
  )
}
