import * as React from "react"

export default function Format({ width = 32, height = 32, fill = '#FFCB1F', ...props }) {
  return (
    <svg style={{ width: width, height: height }} viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M27.28 19.4C27.2758 18.0554 27.1317 16.7148 26.85 15.4H30.45C30.2646 14.8516 30.0407 14.3169 29.78 13.8H26.43C25.8002 11.6961 24.8235 9.71209 23.54 7.93C22.72 7.5852 21.865 7.33038 20.99 7.17C22.7483 9.05924 24.0756 11.3077 24.88 13.76H19.49V7H17.99V13.77H12.6C13.4061 11.3132 14.7368 9.0612 16.5 7.17C15.6288 7.3277 14.7772 7.57916 13.96 7.92C12.6715 9.69462 11.688 11.6718 11.05 13.77H7.68C7.41457 14.2963 7.18731 14.8411 7 15.4H10.63C10.3483 16.7148 10.2042 18.0554 10.2 19.4C10.2028 20.8708 10.3705 22.3366 10.7 23.77H7.16C7.36688 24.3305 7.6142 24.8753 7.9 25.4H11.13C11.7422 27.3158 12.6498 29.1242 13.82 30.76C14.658 31.1194 15.5334 31.3844 16.43 31.55C14.7979 29.7698 13.5428 27.6778 12.74 25.4H18V31.74H19.5V25.4H24.74C23.9345 27.6788 22.6759 29.7708 21.04 31.55C21.9408 31.3787 22.8196 31.107 23.66 30.74C24.8283 29.1104 25.7359 27.3088 26.35 25.4H29.55C29.8345 24.885 30.0818 24.3503 30.29 23.8H26.74C27.0852 22.3584 27.2664 20.8823 27.28 19.4ZM17.99 23.77H12.25C11.546 21.0178 11.5151 18.1366 12.16 15.37H17.99V23.77ZM25.23 23.77H19.49V15.4H25.32C25.6073 16.7137 25.7481 18.0552 25.74 19.4C25.7481 20.872 25.5769 22.3395 25.23 23.77Z" fill="#901859"/>
      <path d="M19 3C15.8355 3 12.7421 3.93838 10.1109 5.69649C7.4797 7.45459 5.42894 9.95345 4.21793 12.8771C3.00693 15.8007 2.69008 19.0177 3.30744 22.1214C3.92481 25.2251 5.44866 28.0761 7.6863 30.3137C9.92394 32.5513 12.7749 34.0752 15.8786 34.6926C18.9823 35.3099 22.1993 34.9931 25.1229 33.7821C28.0466 32.5711 30.5454 30.5203 32.3035 27.8891C34.0616 25.2579 35 22.1645 35 19C35 14.7565 33.3143 10.6869 30.3137 7.68629C27.3131 4.68571 23.2435 3 19 3ZM19 33C16.2311 33 13.5243 32.1789 11.222 30.6406C8.91973 29.1022 7.12532 26.9157 6.06569 24.3576C5.00607 21.7994 4.72882 18.9845 5.26901 16.2687C5.80921 13.553 7.14258 11.0584 9.10051 9.1005C11.0584 7.14257 13.553 5.8092 16.2687 5.26901C18.9845 4.72881 21.7994 5.00606 24.3576 6.06569C26.9157 7.12531 29.1022 8.91973 30.6406 11.222C32.1789 13.5243 33 16.2311 33 19C33 22.713 31.525 26.274 28.8995 28.8995C26.274 31.525 22.713 33 19 33Z" fill="#901859"/>
    </svg>
  )
}
