import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Button from '../components/Button.js'
import TextWithIcon from "../components/TextWithIcon.js"

import Profile from "../icons/Profile.js"
import Classification from "../icons/Classification.js"
import Format from "../icons/Format.js"

export default function Curso({
  title = "",
  toPath = "",
  whom = "",
  dinamics = "",
  contents = "",
  format = "",
  profile = "",
  classification = "",
  image,
  ...others
}) {

  if (!image) image = { localFile: "../../images/post-image.png", altText: "Placeholder Image" }

  return (
    <div className="col-md-5 d-flex mb-4">
      <div className="bg-color-white p-2r rounder d-flex flex-column justify-content-between">
        <div className="packing">
          <Button to={toPath}>
            <GatsbyImage
              image={getImage(image.localFile)}
              alt={image.altText}
              objectFit="cover"
              className="roundr-top overlapped card-feature-image bg-pattern-gradient-yellow"
            />
          </Button>
          <h4 className="h2 mb-2r c-purple">
            {title}
          </h4>

          <TextWithIcon
            icon={<Format />}
            label="Formato: "
            text={format}
          />
          <TextWithIcon
            icon={<Profile />}
            label="Perfil: "
            text={profile}
          />
          <TextWithIcon
            icon={<Classification />}
            label="Nível: "
            text={classification}
          />

          <p className="mt-2r mb-2r">{contents}</p>

          <p className="c-purple-2 bold">Dinâmica</p>
          <p className="c-grey mb-2r">{dinamics}</p>

          <p className="c-purple-2 bold">Para quem é?</p>
          <p className="c-grey mb-2r">{whom}</p>
        </div>
        <Button className="bttn outline c-purple h3 jc-c" to={toPath}>
          Conheça o Curso
        </Button>
      </div>
    </div>
  )
}