import * as React from "react"

export default function Classification({ width = 32, height = 30, fill = '#FFCB1F', ...props }) {
  return (
    <svg style={{ width: width, height: height }} viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M32.0003 29.7143H22.8574V2C22.8574 0.895429 23.7529 0 24.8574 0H30.0003C31.1048 0 32.0003 0.895431 32.0003 2V29.7143ZM25.1431 27.4286H29.7146V3.28571C29.7146 2.73343 29.2668 2.28571 28.7146 2.28571H26.1431C25.5909 2.28571 25.1431 2.73343 25.1431 3.28571V27.4286Z" fill="#901859"/>
      <path d="M20.5716 29.7143H11.4287V11.1429C11.4287 10.0383 12.3241 9.14288 13.4287 9.14288H18.5716C19.6761 9.14288 20.5716 10.0383 20.5716 11.1429V29.7143ZM13.7144 27.4286H18.2859V12.4286C18.2859 11.8763 17.8381 11.4286 17.2859 11.4286H14.7144C14.1621 11.4286 13.7144 11.8763 13.7144 12.4286V27.4286Z" fill="#901859"/>
      <path d="M9.14286 29.7143H0V18C0 16.8954 0.895431 16 2 16H7.14286C8.24743 16 9.14286 16.8954 9.14286 18V29.7143ZM2.28571 27.4286H6.85714V19.2857C6.85714 18.7334 6.40943 18.2857 5.85714 18.2857H3.28571C2.73343 18.2857 2.28571 18.7334 2.28571 19.2857V27.4286Z" fill="#901859"/>
    </svg>
  )
}