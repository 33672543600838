import * as React from "react";

export default function TextWithIcon({
  icon,
  text,
  label
}) {
  return (
    <div className="mb-3 d-flex align-items-center">
      {icon}
      <div className="packing ps-2">
        <span className="c-purple-2 v-a-t">{label}</span>
        <span className="v-a-t">{text}</span>
      </div>
    </div>
  )
}