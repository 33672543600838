import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import { Helmet } from "react-helmet"

import Container from "../common/helpers/Container.js"
import ContainerWithBg from "../common/helpers/ContainerWithBg.js"

import Page from "../common/elements/Page.js"
import Depoimento from "../common/elements/Depoimento.js"
import Curso from "../common/elements/Curso.js"

export default function CursosPage({data}){
  return (
    <Page>
      <Seo post={data.wpPage} />
      <Helmet>
        <link rel="preload" as="image" href="../images/bg-cursos-hero.png"></link>
      </Helmet>
      <ContainerWithBg 
        classBgName="bg-pattern bg-pattern-linear-darker bg-image-cursos-hero py-8 py-lg-0"
        className="container-md tac mh-27r line-lenght-large ai-c jc-c dfc c-white p-r z1 feature-image"
      >
        <h1 className="h1-egg mb-4">Cursos</h1>
        <p className="mb-4">
          Sua imersão no mundo do diagnóstico e planejamento de comunicação integrada, gestão de crises e riscos e mapeamento de projetos começa agora!
        </p>
      </ContainerWithBg>

      <ContainerWithBg
        classBgName="bg-color-purple-3 mt--2r pb-4r"
        className="container-xl"
      >
        <div className="row justify-content-center">          
        {
          data.allWpCurso.nodes.map(node => (
            <Curso 
              key={node.id}
              title={node.title}
              format={node.cursosFields.comunicacaoCursosFormatos}
              profile={node.cursosFields.comunicacaoCursosPerfil}
              classification={node.cursosFields.comunicacaoCursosNivel}
              contents={node.cursosFields.comunicacaoCursosDescricao}
              dinamics={node.cursosFields.comunicacaoCursosDinamica}
              whom={node.cursosFields.comunicacaoCursosQuem}
              image={(node.featuredImage) ? node.featuredImage.node : null}
              toPath={node.link}
            />
          ))
        }
        </div>
      </ContainerWithBg>

      <Container className="container-fluid">
        <div className="row">
          <div className="col-md-6 order-md-2 bg-color-golden d-flex align-items-center">
            <div className="col-size-720 p-4 p-lg-8">
              <h4 className="mb-4 h2 c-purple">Benefícios de ser um <br/> Comunicador Integrado</h4>
              <p className="pb-3">Potencial para atuar em áreas de consultoria, gestão e liderança, unindo conhecimentos aprofundados, estratégicos e que respondem aos desafios atuais;</p>
              <p className="pb-3">Capacidade de realizar diagnósticos e leituras de cenários, antecipando riscos e crises;</p>
              <p className="pb-3">Visão holística de todos os processos de comunicação, do plano até o monitoramento, passando pela gestão estratégica;</p>
              <p className="pb-3">Habilidade em planejar a comunicação considerando pessoas, processos e estratégias (on e offline).</p>
            </div>
          </div>
          <div className="col-md-6 order-md-1 d-flex p-0">
            <StaticImage src="../images/cursos-beneficios.png" alt="Cursos" />
          </div>
        </div>
        
      </Container>
      
      <ContainerWithBg classBgName="bg-color-purple-3" className="pb-4r pt-4r" noDisplay>
        <div className="container-fluid">
          <h4 className="c-purple mb-4 h2 tac">Depoimentos</h4>
          <div className="row justify-content-center">
            <Depoimento 
              text="Sem palavras para descrever o que foi participar desse curso de Diagnóstico e Planejamento de Comunicação Integrada, ministrado pela Isabela Pimentel. A Comunicação Integrada - Cursos e Soluções é uma escola incrível, que entrega muito além do prometido. Recomendo para todos os meus colegas da área e para gestores que queiram aprimorar a comunicação nas suas empresas.   O curso  traz muita informação valiosa, coisas que não se vê em cursos por aí. Conteúdo relevante, aprofundado, caminhando com o olhar da Gestão atrelado à prática."
              jobTitle="Assessora de Imprensa"
              company="Dulcelene Jatobá"
            />
            <Depoimento 
              text="Eu agradeço por um curso tão completo, meticulosamente elaborado e repleto de experiências e referências de alto nível. Será valiosíssimo para meus objetivos profissionais. É uma grande satisfação ver um arcabouço técnico e teórico tão vasto e diversificado sendo aplicado com efetividade em projetos de comunicação. Recomendo muito esse curso aos colegas comunicadores. Sugiro também a gestores que já compreenderam que a comunicação não é apenas mais uma caixinha a ser gerida dentro de um organograma. É, sim, uma função estratégica indispensável para a concretização de objetivos de negócio."
              jobTitle="Profissional de Comunicação Corporativa"
              company="Rogerio Correa de Castro"
            />
            <Depoimento 
              text="Gratidão à Isabela Pimentel por todo o conteúdo repassado com muito profissionalismo e generosidade. Nos mantermos reciclados com tantas tecnologias e metodologias que vão surgindo é um desafio diário. E para enfrentar um mercado tão disruptivo é vital investir e estudar sempre! Que venham os próximos cursos! Recomendo muito seu trabalho, que alia teoria e práticas ágeis!    O curso Avançado em Diagnóstico e Planejamento de Comunicação Integrada foi um 'achado', pois concilia profundidade, atualidade e casos de mercado."
              jobTitle="Consultora e Diretora na InterMidia Comunicação Integrada"
              company="Adriana Vieira"
            />
          </div>
        </div>
      </ContainerWithBg>
    </Page>
  )
}

export const pageQuery = graphql`
  query {
    allWpCurso(sort: { fields: [title] }) {
      nodes {
        id
        title
        link
        cursosFields {
          comunicacaoCursosDescricao
          comunicacaoCursosDinamica
          comunicacaoCursosFormatos
          comunicacaoCursosNivel
          comunicacaoCursosPerfil
          comunicacaoCursosQuem
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    wpPage(databaseId: {eq: 19033}) {
      nodeType
      title
      uri
      seo {
          title
          metaDesc
          focuskw
          metaKeywords
          metaRobotsNoindex
          metaRobotsNofollow
          opengraphTitle
          opengraphDescription
          opengraphImage {
              altText
              sourceUrl
              srcSet
          }
          twitterTitle
          twitterDescription
          twitterImage {
              altText
              sourceUrl
              srcSet
          }
          canonical
          cornerstone
          schema {
              articleType
              pageType
              raw
          }
      }
    }
  }
`